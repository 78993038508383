<template>
  <v-row>
    <v-col v-if="$vuetify.breakpoint.mdAndUp" cols="6" class="pb-0 pr-0">
      <div class="wrapper">
        <div class="wrapper-img">
          <v-img height="100%" src="../../assets/img/group-80.svg"></v-img>
        </div>
      </div>
    </v-col>
    <v-col cols="12" md="6" class="pb-0 pl-2 pl-md-0">
      <div class="form-container">
        <div class="d-flex justify-space-between pt-8 pl-8" style="width: 100%">
          <div class="d-flex align-center">
            <span @click="$router.go(-1)" class="back-btn">
              <v-icon size="24">mdi-arrow-left-thick </v-icon>
            </span>
          </div>
          <div>
            <h2 class="header-text">{{ $t("login.welcome") }}</h2>
          </div>
          <div></div>
        </div>
        <form class="form">
          <h4 class="form-header mb-8 mb-md-11">{{ $t("login.title") }}</h4>
          <div class="textfield mb-8 mb-md-11">
            <label class="label" for="email">{{ $t("login.email") }}</label>
            <input class="input" type="text" id="email" />
          </div>
          <div class="textfield mb-8 mb-md-11">
            <label class="label" for="password">{{
              $t("login.password")
            }}</label>
            <input class="input" type="password" id="password" />
            <v-checkbox hide-details color="indigo" class="ml-8 mt-2">
              <template v-slot:label>
                <p class="black--text mb-0">{{ $t("login.keepMeSignIn") }}</p>
              </template>
            </v-checkbox>
          </div>
          <v-btn class="btn-main h-48 mt-2" width="100%">{{
            $t("login.title")
          }}</v-btn>
        </form>
        <div></div>
      </div>
    </v-col>
  </v-row>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
@import "./index.scss";
</style>
